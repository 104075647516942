import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NavigatorOnLineService } from '@core/services';
import { Status, UserAD } from '@digi.me/models';
import { environment } from '@environments/environment';
import { StorageKeys } from '@globals';
import { MenuItem, MenuService } from '@navigation/menu';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated, selectPageHeader } from '@store/app';
import {
  selectFileListStatus,
  selectIsLoading,
  selectMustCreateAccount,
  selectShouldReauthorize,
  selectShowWarningUpdate,
  selectUserAd,
  SUMMARY_EXPORT_ACTIONS,
} from '@store/digi.me';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-menu-navbar',
  templateUrl: './menu-navbar.component.html',
  styles: [
    `
      @media (max-width: 767px) {
        .logo {
          width: auto;
        }
      }

      @media (min-width: 768px) {
        .logo {
          width: 21.5rem;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuNavBarComponent {
  readonly locale = `/${$localize.locale}`;
  readonly shouldReauthorize$: Observable<boolean>;
  readonly digiMeStatus$: Observable<Status | undefined>;
  readonly isAuthenticated$: Observable<boolean>;
  readonly showWarningUpdate$ = this.store.select(selectShowWarningUpdate);
  readonly isDataLoading$: Observable<boolean>;
  readonly userAd$: Observable<UserAD | null>;
  readonly selectedItem$: Observable<MenuItem | null> = this.menuService.selectedMenuItemByUrl$;

  readonly pageHeader$: Observable<string> = this.store.select(selectPageHeader);
  online$ = this.navigatorOnlineService.online$;

  readonly environment = environment;
  protected readonly mustCreateAccount$: Observable<boolean> = combineLatest([
    this.store.select(selectMustCreateAccount),
    of(Boolean(localStorage.getItem(StorageKeys.SHOULD_CREATE_ACCOUNT))),
    this.oidcSecurityService.isAuthenticated$,
  ]).pipe(
    map(
      ([libraryExists, shouldCreateAccount, authenticatedResult]) =>
        libraryExists || (shouldCreateAccount && !authenticatedResult.isAuthenticated),
    ),
  );

  constructor(
    private offcanvasService: NgbOffcanvas,
    private readonly router: Router,
    private readonly store: Store,
    private readonly navigatorOnlineService: NavigatorOnLineService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly menuService: MenuService,
  ) {
    this.shouldReauthorize$ = store.select(selectShouldReauthorize);
    this.digiMeStatus$ = store.select(selectFileListStatus);
    this.isAuthenticated$ = store.select(selectIsAuthenticated);
    this.isDataLoading$ = this.store.select(selectIsLoading);
    this.userAd$ = this.store.select(selectUserAd);
  }

  open(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  close() {
    this.offcanvasService.dismiss();
  }

  gotoNotifications() {
    this.router.navigate([`${$localize.locale}`, 'notifications']);
  }

  downloadPDF(): void {
    this.store.dispatch(SUMMARY_EXPORT_ACTIONS.pdfExportRequested());
  }
}
